import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sand & Cement`}</h1>
    <p>{`Modern cement render is much harder and stronger than lime render. A sealed or painted cement render can help a building in certain circumstances by helping prevent penetrating damp. Cement renders are generally mixed using ordinary Portland cement.`}</p>
    <p>{`As these are so rigid they are not suitable for older buildings as they crack and break in no time at all. Hydrated lime can be added to the cement mix to increase durability and flexibility of the render but it by no means cures all problems associated with cement render.`}</p>
    <p>{`Cement render is generally a cheaper alternative to `}<a parentName="p" {...{
        "href": "/render-types/lime-render/",
        "title": "Lime Mortar Render"
      }}>{`Lime Render`}</a>{` or `}<a parentName="p" {...{
        "href": "/render-types/polymer-render/",
        "title": "Polymer Render"
      }}>{`Polymer Renders`}</a>{`. They are often applied as they are cheap for the builder to make and relatively easy to apply. Cement render also cures reasonably quickly when additives are used, but caution should be taken when specifying cement renders as they are incredibly rigid and will crack, spall or delaminate if there is any movement in the substrate or levels of moisture build up too high within the render.`}</p>
    <p>{`Suitable substrate: A cement render would be suited to a modern building, constructed with a double skin wall with wall ties. The wall will need to be permanently dry and free of defects such as movement around windows and must never be applied below the damp course. Metal lath is suitable, other substrate may also be suitable.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      